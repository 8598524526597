html {
  font-family: 'Roboto', sans-serif;
  cursor: default;
}

body {
  margin: 0;
  background-color: #fff;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -64.5px;
  margin-top: -85.5px;
}

.ghost {
  animation: float 3s ease-out infinite;
}

::-webkit-scrollbar {
  width: 16px !important;
  height: 16px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important; 
}
 
::-webkit-scrollbar-thumb {
  background: #cc0000 !important; 
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@-webkit-keyframes float {
  50% {
    transform: translate(0, 20px);
  }
}
.shadow-frame {
  width: 130px;
  margin-top: 15px;
}

@-webkit-keyframes wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.notify-email-body p {
  margin: 0;
  font-size: 16px;
}
.notify-email-body a {
  padding: 10px;
}
.notify-email-body .ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: none;
}
.notify-email-body .ql-toolbar.ql-snow {
  padding-top: 0;
  border: none;
}
.notify-email-body .ql-toolbar.ql-snow .ql-formats {
  border: 1px solid #8d8d8d;
  padding: 5px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}
